import React from 'react';
import { useTranslation } from 'react-i18next';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Translate from './i18n/Translate';
// @ts-ignore
import { formatPrice } from './ProductFormatPrice';
import ColoredTableRow from './ColoredTableRow';
import { AdditionalAttributesDialogButton } from '../additional-attributes';
import TextWithSubText from './TextWithSubText';
import { ensureString, withConditionalBrackets } from '../utils/stringUtils';
import { ReferringItem } from '../orders/orderHelper';

interface ItemTableRowProps {
  item: ReferringItem;
  currency: string;
  isColored?: boolean;
}

export default function ItemTableRow({
  item,
  currency,
  isColored = false,
}: ItemTableRowProps) {
  const { t } = useTranslation();
  if (!item || !currency) return null;

  const StyledTableCell = ({ children, ...props }: TableCellProps) => (
    <TableCell size="small" sx={{ borderBottom: 'none' }} {...props}>
      {children}
    </TableCell>
  );

  const translatedType = t(`orders.itemType.${item.type}`);
  const discountNameWithValue = withConditionalBrackets(
    ensureString(item.name),
    (!!item.discountPercentage && `${item.discountPercentage}%`)
    || (!!item.discountValue && `${formatPrice(item.discountValue, currency)}`)
    || undefined,
  );
  const reasonMessageWithCode = withConditionalBrackets(
    item.discountReasonMessage,
    item.discountReasonCode,
    { fallbackToBracketValue: true },
  );

  const isDiscount = item.type === 'manualDiscount' || item.type === 'discount';
  const isCartDiscount = !item.triggeringItem?.refersTo;
  const translatedDiscountType = t(`orders.lineItem.${isCartDiscount ? 'cartDiscount' : 'lineItemDiscount'}`);

  const additionalData = {
    ...item.additionalAttributes,
    Name: item.name,
    ...(item.discountPercentage && { DiscountPercentage: item.discountPercentage }),
    ...(item.discountValue && { DiscountValue: formatPrice(item.discountValue, currency) }),
    ...(item.discountReasonMessage && { Message: item.discountReasonMessage }),
    ...(item.discountReasonCode && { Code: item.discountReasonCode }),
    Type: translatedType,
    ...(isDiscount && { DiscountType: translatedDiscountType }),
  };

  return (
    <>
      <ColoredTableRow isColored={isColored}>
        <StyledTableCell sx={{ textAlign: 'center', borderBottom: 'none' }}>
          <SubdirectoryArrowRightIcon fontSize="small" />
        </StyledTableCell >
        <StyledTableCell>
          <TextWithSubText
            text={discountNameWithValue}
            subText={reasonMessageWithCode}
          />
        </StyledTableCell>
        <StyledTableCell>
          {isDiscount ? translatedDiscountType : translatedType}
        </StyledTableCell>
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell />
        <StyledTableCell>
          {
            !item.priceModifiers && !(item.priceModifiers || []).length &&
            (formatPrice(item.totalPrice, currency) || '')
          }
        </StyledTableCell>
        <StyledTableCell align="right">
          {additionalData && (
            <AdditionalAttributesDialogButton attributes={additionalData} />
          )}
        </StyledTableCell>
      </ColoredTableRow>

      {(item.priceModifiers || []).map(modifier => (
        <ColoredTableRow key={`${item.id} -${modifier.name} -${modifier.price} `} isColored={isColored}>
          <StyledTableCell sx={{ textAlign: 'center', borderBottom: 'none' }}>
            <SubdirectoryArrowRightIcon fontSize="small" />
          </StyledTableCell >
          <StyledTableCell><Translate id={`orders.priceModifierMethod.${modifier.method} `} /></StyledTableCell>
          <StyledTableCell><Translate id={`orders.priceModifierAction.${modifier.action} `} /></StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell>{formatPrice(modifier.price, currency)}</StyledTableCell>
          <StyledTableCell />
          <StyledTableCell align="right">
            {modifier.reasonCode && (
              <AdditionalAttributesDialogButton
                attributes={{ reasonCode: modifier.reasonCode }}
              />
            )}
          </StyledTableCell>
        </ColoredTableRow>
      ))}
      {item.priceModifiers && (
        <ColoredTableRow isColored={isColored}>
          <StyledTableCell />
          <StyledTableCell colSpan={6} />
          <StyledTableCell>
            {formatPrice(item.totalPrice, currency) || '-/-'}
          </StyledTableCell>
          <StyledTableCell />
        </ColoredTableRow>
      )}
    </>
  );
}
