import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DocumentTitle from '../components/DocumentTitle';
import ResourceChartView from '../charts/scaffold/ResourceChartView';
import OrderViewGeneral from '../components/OrderViewGeneral';
// @ts-ignore
import OrderViewDevice from '../components/OrderViewDevice';
import OrderViewLineItems from '../components/OrderViewLineItems';
// @ts-ignore
import OrderViewShop from '../components/OrderViewShop';
// @ts-ignore
import OrderViewFulfillments from '../components/OrderViewFulfillments';
import OrderViewMeta from '../components/OrderViewMeta';
// @ts-ignore
import OrderViewSupervising from '../components/OrderViewSupervising';
import FiscalAttributesPanel from '../components/FiscalAttributesPanel';
import { ApplicationState } from '../reducers/initialState';
import useOrderApi from './useOrderApi';
import Headline from '../components/Headline';
import Translate from '../components/i18n/Translate';
// @ts-ignore
import { formatPrice } from '../components/ProductFormatPrice';
import { MappedSupervisingResult } from './supervisingTypes';
import OrderPaymentResult from './OrderPaymentResult';
import { getTotalSurcharge, MappedTransaction } from './orderHelper';

interface OrderViewContentProps {
  values: MappedTransaction;
  projectCurrency: string;
  receipt: any;
  supervising: MappedSupervisingResult | null;
}

export function OrderViewContent({
  values,
  projectCurrency,
  receipt,
  supervising,
}: OrderViewContentProps) {
  const transaction = values;

  return (
    <>
      <OrderViewGeneral transaction={transaction} receipt={receipt} />

      {transaction.items?.length > 0 && (
        <OrderViewLineItems
          lineItems={transaction.items}
          referringItems={transaction.referringItems || {}}
          cartDiscountInfo={transaction.cartDiscountInfo || []}
          currency={projectCurrency}
          price={{
            price: transaction.price,
            subTotal: transaction.subTotal,
            netPrice: transaction.netPrice,
            surcharge: getTotalSurcharge(transaction),
          }}
          project={transaction.project}
        />
      )}

      <OrderPaymentResult transaction={transaction} />

      <OrderViewSupervising result={supervising} />

      <OrderViewDevice transaction={transaction} />

      <OrderViewShop
        shop={transaction.shop}
        projectID={transaction.project}
        id={transaction.shop?.id}
      />

      <OrderViewFulfillments order={transaction} />

      <OrderViewMeta order={transaction} />

      {transaction.fiscalReference && (
        <FiscalAttributesPanel attributes={transaction.fiscalReference} />
      )}
    </>
  );
}

export default function OrderView() {
  const projectCurrency = useSelector((state: ApplicationState) => state.projectCurrency);
  const { id } = useParams();
  const orderApi = useOrderApi();

  const [transaction, setTransaction] = useState<MappedTransaction | null>(null);
  const [supervising, setSupervising] = useState<MappedSupervisingResult | null>(null);
  const [receipt, setReceipt] = useState<any>(null);

  const fetch = useCallback(async () => {
    if (!id) return {};

    const transactionResult = await orderApi.getOrder(id);
    setTransaction(transactionResult);

    const supervisingResult =
      await orderApi.getSupervisingResult(id);
    setSupervising(supervisingResult);

    const receiptBlob = await orderApi.getReceipt(transactionResult.links?.receipt);
    setReceipt(receiptBlob);

    return transactionResult;
  }, [id, orderApi]);

  return (
    <>
      <DocumentTitle
        translationID="orders.detailsHeadline"
        data={{
          orderID: transaction?.shortID,
          total: formatPrice(transaction?.price, projectCurrency || ''),
        }}
      />
      <Headline>
        <Translate
          id="orders.detailsHeadline"
          data={{ orderID: transaction?.shortID, total: formatPrice(transaction?.price, projectCurrency || '') }}
        />
      </Headline>
      <ResourceChartView
        Chart={OrderViewContent}
        ChartProps={{
          projectCurrency,
          receipt,
          supervising,
        }}
        fetch={fetch}
      />
    </>
  );
}
