import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import VisibleIcon from '@mui/icons-material/Done';
import NotVisibleIcon from '@mui/icons-material/Block';
import Paper from '@mui/material/Paper';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import {
  CustomFormProps,
  Form,
  useEnhancedForm,
} from '../form';
import { ColumnsProp, formatCell, SortableTable } from '../SortableTable';
import useProjectNavigate from '../useProjectNavigate';
// @ts-ignore
import { ReactComponent as ShopAccessIcon } from '../icons/passkey.svg';
import TranslatedTooltip from '../components/i18n/TranslatedTooltip';
import PanelHeader from '../panel/PanelHeader';

const DEFAULT_VALUES = {};

export default function EditAppUserForm({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: CustomFormProps) {
  const { t } = useTranslation();
  const navigate = useProjectNavigate();

  const data = defaultValues;
  const {
    handleSubmit,
  } = useEnhancedForm({ defaultValues, errors });

  const handleViewUserInShopAccessJournal = useCallback(() => {
    if (!data?.phoneNumber) return;
    navigate('/shop-access/', {
      state: {
        presetIdFilter: data.phoneNumber,
      },
    });
  }, [data.phoneNumber, navigate]);

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'createdAt',
      disablePadding: false,
      displayName: t('appUser.agreementList.createdAt'),
      type: 'datetime',
    },
    {
      key: 'document',
      disablePadding: false,
      displayName: t('appUser.agreementList.document'),
    },
    {
      key: 'version',
      disablePadding: false,
      displayName: t('appUser.agreementList.version'),
    },
  ], [t]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <PaperTable headline={t('appUser.headlines.userDetails')}>
          <PaperTableRow
            label={t('appUser.firstName')}
            value={data?.details?.firstName || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.lastName')}
            value={data?.details?.lastName || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.dateOfBirth')}
            value={data?.details?.dateOfBirth || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.email')}
            value={data?.details?.email || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.street')}
            value={data?.details?.street || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.zip')}
            value={data?.details?.zip || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.city')}
            value={data?.details?.city || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.state')}
            value={data?.details?.state || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.country')}
            value={data?.details?.country || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.acceptedPrivacyTermsAt')}
            value={data?.details?.acceptedPrivacyTermsAt || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.acceptedTermsOfUseAt')}
            value={data?.details?.acceptedTermsOfUseAt || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.emailIsVerified')}
            value={data.emailIsVerified ? <VisibleIcon /> : <NotVisibleIcon />}
          />
          <PaperTableRow
            label={t('appUser.detailsCreatedAt')}
            value={data?.details?.createdAt ? formatCell(data?.details?.createdAt, 'datetime', { invalidDateLabel: t('form.errors.types.invalidDate') }) : '-/-'}
          />
          <PaperTableRow
            label={t('appUser.detailsUpdatedAt')}
            value={data?.details?.updatedAt ? formatCell(data?.details?.updatedAt, 'datetime', { invalidDateLabel: t('form.errors.types.invalidDate') }) : '-/-'}
          />
        </PaperTable>

        <PaperTable headline={t('appUser.headlines.user')}>
          <PaperTableRow
            label={t('appUser.id')}
            value={data?.id}
          />
          <PaperTableRow
            label={t('appUser.phoneNumber')}
            value={(
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <span>{data?.phoneNumber || '-/-'}</span>
                {data?.phoneNumber && (
                  <TranslatedTooltip title="viewUserInShopAccessJournal">
                    <IconButton size="small" onClick={handleViewUserInShopAccessJournal} sx={{ padding: 0 }}>
                      <ShopAccessIcon />
                    </IconButton>
                  </TranslatedTooltip>
                )}
              </Stack>
            )}
          />
          <PaperTableRow
            label={t('appUser.userCreatedAt')}
            value={data?.createdAt ? formatCell(data?.createdAt, 'datetime', { invalidDateLabel: t('form.errors.types.invalidDate') }) : '-/-'}
          />
        </PaperTable>

        <Paper elevation={4}>
          <PanelHeader>{t('appUser.headlines.agreements')}</PanelHeader>
          {data.agreements?.length ?
            <SortableTable
              data={data.agreements || []}
              columnsMetadata={headCells}
              title=""
              filterPlaceholder={t('appUser.agreementList.filterPlaceholder')}
              labelRowsPerPage={t('appUser.agreementList.labelRowsPerPage')}
              verticalToolbarPadding={4}
            />
            :
            <div style={{ fontWeight: 400, fontSize: '0.875rem', margin: '16px' }}>
              {t('appUser.agreementList.empty')}
            </div>
          }
        </Paper>
      </Form>
    </>
  );
}
