// This file is a port of some of the functions from the reducer/helper.js file
// It was converted to typescript, typed and refactored to be a bit easier to read.
import shopLabel from '../shop';
import LineItem from './types/LineItemTypes';
import { Fulfillment, Transaction } from './types/TransactionsTypes';

function mapFulfillmentItems(fulfillment: Fulfillment, lineItems: LineItem[]) {
  const referredItems: LineItem[] = [];

  if (!fulfillment.refersTo) return referredItems;

  fulfillment.refersTo.forEach((refersTo) => {
    const referredItem = lineItems.find(lineItem => lineItem.id === refersTo);
    if (referredItem) {
      referredItems.push(referredItem);
    }
  });

  return referredItems;
}

type MappedFulfillment = Fulfillment & { items: LineItem[] };

function addReferedToItemsToFulfillments(transaction: Transaction) {
  if (!transaction || !transaction.fulfillments || !transaction.lineItems) return [];

  const { lineItems } = transaction;

  const fulfillments: MappedFulfillment[] = transaction.fulfillments.map(fulfillment => ({
    ...fulfillment,
    items: mapFulfillmentItems(fulfillment, lineItems),
  }));
  return fulfillments;
}

function isTerminalAborted(order: any) {
  if (!order) return false;

  // Aborted through the app
  if (order.state === 'userAborted') return true;

  // Aborted through the terminal
  if (
    order.state === 'paymentFailed'
    && order.paymentResult?.failureCause === 'terminalAbort'
  ) return true;

  // Aborted through softpos
  if (
    order.state === 'paymentFailed'
    && order.paymentResult?.failureCause === 'userAborted'
  ) return true;
  return false;
}

function ageVerificationError(order: any) {
  if (!order || order.state !== 'paymentFailed') return false;
  return (
    order.paymentResult?.failureCause === 'ageVerificationNotSupportedByCard' ||
    order.paymentResult?.failureCause === 'ageVerificationFailed'
  );
}

function translationKeyOrderState(order: any) {
  if (!order) return '';
  if (isTerminalAborted(order)) {
    return 'orders.state.userAborted';
  }
  if (ageVerificationError(order)) {
    return `orders.state.${order.paymentResult.failureCause}`;
  }
  return `orders.state.${order.state}`;
}

export interface CartDiscountInfo {
  id: LineItem['id'];
  name: LineItem['name'];
  code: LineItem['discountReasonCode'];
  message: LineItem['discountReasonMessage'];
  discountPercentage: LineItem['discountPercentage'] | undefined;
  discountValue: LineItem['price'] | 0;
}

export function extractCartDiscounts(lineItems: LineItem[] | undefined) {
  const cartDiscounts: CartDiscountInfo[] = [];
  const filteredLineItems: LineItem[] = [];

  if (!lineItems || !lineItems.length) return { filteredLineItems, cartDiscounts };

  lineItems.forEach((item) => {
    if (!!item.refersTo || item.type !== 'manualDiscount') { // normal line item
      filteredLineItems.push(item);
      return;
    }

    cartDiscounts.push({
      id: item.id,
      name: item.name,
      code: item.discountReasonCode,
      message: item.discountReasonMessage,
      discountPercentage: item.discountPercentage ?? undefined,
      discountValue: item.price ?? 0,
    });
  });

  return { filteredLineItems, cartDiscounts };
}

type RequiredRefersTo = Required<Pick<LineItem, 'refersTo'>>['refersTo'];
type RequiredItemID = Required<Pick<LineItem, 'itemID'>>['itemID'];
export interface ReferringItem extends LineItem {
  triggeringItem?: LineItem;
}

function extractReferringLineItems(lineItems: LineItem[]) {
  // items that refer to other items, like discounts, etc.
  const referringItems: Record<RequiredRefersTo, ReferringItem[]> = {};
  // items that cause other referring items to be created.
  // e.g. a manual discount causes a discount to be added to the cart
  const triggeringItems: Record<RequiredItemID, LineItem> = {};
  const standardLineItems: LineItem[] = [];

  (lineItems || []).forEach((lineItem) => {
    if (lineItem.refersTo && lineItem.type !== 'manualDiscount') {
      referringItems[lineItem.refersTo] = [...(referringItems[lineItem.refersTo] || []), lineItem];
    } else if (lineItem.refersTo && lineItem.itemID) {
      triggeringItems[lineItem.itemID] = lineItem;
    } else {
      standardLineItems.push(lineItem);
    }
  });

  // move triggering items into referring items as they are not used anywhere else but are needed
  // to correctly display the referring items
  Object.keys(referringItems).forEach((key) => {
    referringItems[key] = referringItems[key].map((item) => {
      if (!item.itemID) return item;
      const triggeringItem = triggeringItems[item.itemID];
      return Object.assign({}, item, { triggeringItem });
    });
  });

  return { standardLineItems, referringItems };
}

export function mapOrder(transaction: Transaction): MappedTransaction | {} {
  if (!transaction) return {};

  const { filteredLineItems, cartDiscounts } = extractCartDiscounts(transaction.lineItems);
  const { standardLineItems, referringItems } = extractReferringLineItems(filteredLineItems);
  const fulfillments = addReferedToItemsToFulfillments(transaction);

  let hasError = false;
  if (transaction.state === 'final' && fulfillments && fulfillments.length) {
    hasError = !!fulfillments.find((fulfillment => fulfillment.state !== 'processed'));
  }

  return Object.assign({}, transaction, {
    items: standardLineItems,
    referringItems,
    cartDiscountInfo: cartDiscounts,
    fulfillments,
    shortID: transaction.id
      ? transaction.id.substring(0, 8)
      : transaction.id,
    shortAppUserID: transaction.appUser?.id
      ? transaction.appUser.id.substring(0, 8)
      : transaction.appUser?.id,
    shortCheckoutDeviceID: transaction.checkoutDevice?.id
      ? transaction.checkoutDevice.id.substring(0, 8)
      : transaction.checkoutDevice?.id,
    shop: Object.assign({}, transaction.shop, { label: shopLabel(transaction.shop) || '' }),
    stateTranslation: translationKeyOrderState(transaction),
    isTerminalAborted: isTerminalAborted(transaction),
    hasError,
  });
}

export type MappedTransaction = Transaction & {
  items: LineItem[];
  referringItems: Record<RequiredRefersTo, LineItem[]>;
  cartDiscountInfo: CartDiscountInfo[];
  shortID: string;
  shortAppUserID: string | undefined;
  shortCheckoutDeviceID: string | undefined;
  fulfillments: MappedFulfillment[];
  shop: Transaction['shop'] & { label: string };
  stateTranslation: string;
  isTerminalAborted: boolean;
  hasError: boolean;
};

export function hasFulfillmentError(transaction: Transaction) {
  let hasError = false;

  const { state, fulfillments } = transaction;

  if (state === 'final' && fulfillments?.length) {
    hasError = !!fulfillments.find((fulfillment => fulfillment.state !== 'processed'));
  }
  return hasError;
}

export function getTotalSurcharge(transaction: Transaction): number {
  return transaction.payments?.reduce((totalSurcharge, payment) => (
    payment.surcharge ? totalSurcharge + payment.surcharge : totalSurcharge
  ), 0) || 0;
}
