import { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import { ApplicationState } from '../reducers/initialState';
import {
  rangeFromQuery,
  shopFromQuery,
  customerCardFromQuery,
  paymentMethodsFromQuery,
  toSearchQuery,
  toShopQuery,
  toRangeQuery,
  toCustomerCardQuery,
  toPaymentMethodQuery,
  fromQuery,
} from '../urls';
import { validateStatsTimeRange } from '../ranges';
import {
  updateRange,
  updateShopFilter,
  updateWithCustomerCardFilter,
  updatePaymentMethodsFilter,
  updateGenericFilter,
} from './actions/filter';
import {
  UseChartFilerProps,
  Range,
  ShopFilter,
  WithCustomerCardFilter,
  PaymentMethodsFilter, Filter,
} from './chartFilterProps';

type UpdateFilter = Partial<Filter>;

function getGenericFilterFromUrlQuery(search: string, keys: string[] | undefined) {
  if (!keys) return {};
  const genericFilter: Record<string, any> = {};
  keys.forEach((key) => {
    const value = fromQuery(search, key);
    if (value) genericFilter[key] = value;
  });
  return genericFilter;
}

function getUrlQueryFromGenericFilter(filter: Record<string, any>, keys: string[] = []) {
  const query: Record<string, any> = {};
  keys.forEach((key) => {
    query[key] = filter[key];
  });
  return query;
}

export default function useChartFilter({
  urlQuery,
  autoUpdateUrl,
  defaultValues,
  genericFilterInUrl,
}: UseChartFilerProps) {
  const rangeFromStore: Range = useSelector<ApplicationState, Range>(state => state.range);
  const shopFilterFromStore: ShopFilter =
    useSelector<ApplicationState, ShopFilter>(state => state.shopFilter);
  const withCustomerCardFilterFromStore: WithCustomerCardFilter =
    useSelector<ApplicationState, WithCustomerCardFilter>(state => state.withCustomerCardFilter);
  const paymentMethodsFilterFromStore: PaymentMethodsFilter =
    useSelector<ApplicationState, PaymentMethodsFilter>(state => state.paymentMethodsFilter);
  const genericFilterFromStore: Record<string, any> =
    useSelector<ApplicationState, Record<string, any>>(state => state.genericFilter);

  const defaultRange: Range = rangeFromQuery(urlQuery || '', rangeFromStore);
  const defaultShop: ShopFilter = shopFromQuery(urlQuery || '') as string || shopFilterFromStore;
  const defaultWithCustomerCard: WithCustomerCardFilter =
    customerCardFromQuery(urlQuery || '') || withCustomerCardFilterFromStore;
  const defaultPaymentMethods: PaymentMethodsFilter =
    paymentMethodsFromQuery(urlQuery || '') || paymentMethodsFilterFromStore;
  const defaultGenericFilter: Record<string, any> =
    Object.assign({}, genericFilterFromStore, getGenericFilterFromUrlQuery(urlQuery || '', genericFilterInUrl));

  const [filter, setFilter] = useState<Filter>({
    ...(defaultValues || {}),
    range: defaultRange,
    shopID: defaultShop,
    withCustomerCard: defaultWithCustomerCard,
    paymentMethods: defaultPaymentMethods,
    ...validateStatsTimeRange(defaultRange),
    genericFilter: defaultGenericFilter,
  });

  const [debouncedFilter, setDebouncedFilter] = useState<Filter>({
    ...(defaultValues || {}),
    range: defaultRange,
    shopID: defaultShop,
    withCustomerCard: defaultWithCustomerCard,
    paymentMethods: defaultPaymentMethods,
    ...validateStatsTimeRange(defaultRange),
    genericFilter: defaultGenericFilter,
  });

  const debouncedSetDebouncedFilter = useMemo(() => (
    debounce(setDebouncedFilter, 500)
  ), []);

  const dispatch = useDispatch();

  const updateFilter = (filterUpdate: UpdateFilter) => {
    let newFilter: Filter = { ...filter, ...filterUpdate };

    if (filterUpdate.range !== undefined) {
      newFilter = {
        ...newFilter,
        ...validateStatsTimeRange(filterUpdate.range),
      };
    }

    setFilter(newFilter);
    debouncedSetDebouncedFilter(newFilter);
  };

  const resetFilter = () => {
    setFilter({
      range: defaultRange,
      shopID: defaultShop,
      withCustomerCard: defaultWithCustomerCard,
      paymentMethods: defaultPaymentMethods,
      ...validateStatsTimeRange(defaultRange),
      defaultGenericFilter,
    });
  };

  const buildFilterQueryString = useCallback(() => toSearchQuery({
    ...toRangeQuery(filter.range),
    ...toShopQuery(filter.shopID),
    ...toCustomerCardQuery(filter.withCustomerCard),
    ...toPaymentMethodQuery(filter.paymentMethods),
    ...getUrlQueryFromGenericFilter(filter.genericFilter, genericFilterInUrl),
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [
    filter.genericFilter,
    filter.paymentMethods,
    filter.range,
    filter.shopID,
    filter.withCustomerCard,
  ]);

  useEffect(() => {
    if (filter.hasError) return;

    dispatch(updateRange(filter.range));
    dispatch(updateShopFilter(filter.shopID));
    dispatch(updateWithCustomerCardFilter(filter.withCustomerCard));
    dispatch(updatePaymentMethodsFilter(filter.paymentMethods));
    dispatch(updateGenericFilter(filter.genericFilter));

    if (autoUpdateUrl) {
      const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${buildFilterQueryString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
  }, [autoUpdateUrl, buildFilterQueryString, dispatch, filter]);

  return {
    filter,
    debouncedFilter,
    updateFilter,
    resetFilter,
    buildFilterQueryString,
  };
}
