import styled from '@mui/system/styled';
import { grey } from '@mui/material/colors';

const ImagePreviewContainer = styled('div')(() => ({
  position: 'relative',
  height: '96.5px',
  aspectRatio: '1/1',
  '> img': {
    boxSizing: 'border-box',
    border: `1px solid ${grey[500]}7f`,
    borderRadius: '4px',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  '> button': {
    position: 'absolute',
    bottom: '4px',
    right: '4px',
    backgroundColor: '#fff8',
  },
  '> svg.placeholder': {
    border: `1px solid ${grey[500]}7f`,
    borderRadius: '4px',
    position: 'absolute',
    padding: '16px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: 'unset',
    height: 'unset',
  },
}));

export default ImagePreviewContainer;
