import React, { ReactNode } from 'react';
import TableRow, { TableRowProps } from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames';

const styles = ({
  row: {
    '&:nth-of-type(even)': {
      background: 'rgba(0,0,0,0.04)',
    },
  },
  isColored: {
    background: 'rgba(0,0,0,0.04)',
  },
  clickable: {
    cursor: 'pointer',
  },
});

interface ColoredTableRowProps {
  classes: any;
  children: ReactNode;
  onClick?: () => void;
  isColored?: boolean;
  tableRowProps?: TableRowProps;
}

function ColoredTableRow({
  classes,
  children,
  onClick,
  isColored,
  tableRowProps,
}: ColoredTableRowProps) {
  if (onClick) {
    return (
      <TableRow
        hover
        className={cn(
          classes.clickable,
          (isColored === undefined) && classes.row,
          isColored && classes.isColored,
        )}
        onClick={onClick}
      >
        {children}
      </TableRow>
    );
  }
  return (
    <TableRow
      className={cn((isColored === undefined) && classes.row, isColored && classes.isColored)}
      {...tableRowProps}
    >
      {children}
    </TableRow>
  );
}

export default withStyles(styles)(ColoredTableRow);
