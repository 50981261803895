import React, { useRef, useState } from 'react';
import { HexColorPicker, HexAlphaColorPicker } from 'react-colorful';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MUITextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/system';
import { FieldProps } from './FieldProps';
import FieldContainer from './FieldContainer';
import useFormLabel from './useFormLabel';
import ValidationError from './ValidationError';
import ColorPreview from '../../color/ColorPreview';
import {
  addHexCodePrefix,
  HEX_CODE_PREFIX,
  removeHexCodePrefix,
} from '../../color/hexCode';
import useUniqueId from '../../useUniqueId';

const PopoverToggleButton = styled('button')({
  background: 'transparent',
  border: 'none',
  right: '1em',
  cursor: 'pointer',
  position: 'absolute',
  padding: 0,
  top: 'calc(56px / 2)',
  transform: 'translateY(-50%)',
  zIndex: 10,
});

export interface ColorFieldProps extends FieldProps<MUITextFieldProps> {
  withPrefix?: boolean;
  withAlpha?: boolean;
}

const MAX_LENGTH = 6;
const ALPHA_LENGTH = 2;

export default function ColorField({
  control,
  disabled,
  name,
  helperText,
  rules,
  type,
  withPrefix,
  withAlpha,
  ...props
}: ColorFieldProps) {
  const label = useFormLabel(name, props.label);
  const [pickerOpen, setPickerOpen] = useState(false);
  const popoverAnchorRef = useRef<HTMLDivElement | null>(null);
  const popoverId = useUniqueId('form_color_popover_');

  let maxLength: number = MAX_LENGTH;
  if (withPrefix) {
    maxLength += HEX_CODE_PREFIX.length;
  }
  if (withAlpha) {
    maxLength += ALPHA_LENGTH;
  }

  const handleOpenPicker = () => {
    setPickerOpen(true);
  };

  const handleClosePicker = () => {
    setPickerOpen(false);
  };

  const sanitizeValue = (value: string | null) => {
    if (withPrefix) return addHexCodePrefix(value);
    return removeHexCodePrefix(value);
  };

  return (
    <FieldContainer>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <Box
              sx={{ position: 'relative', isolation: 'isolate' }}
            >
              <PopoverToggleButton
                type="button"
                onClick={handleOpenPicker}
                aria-describedby={pickerOpen ? popoverId : undefined}
              >
                <div ref={popoverAnchorRef}>
                  <ColorPreview color={value} />
                </div>
              </PopoverToggleButton>

              <MUITextField
                type={type}
                label={label}
                variant="outlined"
                value={sanitizeValue(value)}
                onChange={(e) => {
                  onChange(sanitizeValue(e.target.value));
                }}
                disabled={disabled}
                error={!!error}
                helperText={error ? <ValidationError error={error} /> : helperText}
                fullWidth
                inputProps={{
                  maxLength,
                }}
                {...props}
              />
            </Box>

            <Popover
              id={popoverId}
              open={pickerOpen}
              onClose={handleClosePicker}
              anchorEl={popoverAnchorRef.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{ mt: 1 }}
            >
              <Box sx={{ p: 2 }}>
                {withAlpha ? (
                  <HexAlphaColorPicker
                    color={sanitizeValue(value)}
                    onChange={(v) => {
                      onChange(sanitizeValue(v));
                    }}
                  />
                ) : (
                  <HexColorPicker
                    color={sanitizeValue(value)}
                    onChange={(v) => {
                      onChange(sanitizeValue(v));
                    }}
                  />
                )}
              </Box>
            </Popover>
          </>
        )}
        rules={rules}
      />
    </FieldContainer>
  );
}
