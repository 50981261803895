interface BuildLabelFromArrayProps {
  input: Array<string | undefined>;
  separator?: string;
}

export function buildLabelFromArray({
  input,
  separator = ', ',
}: BuildLabelFromArrayProps): string {
  return input?.filter(Boolean).join(separator) || '';
}

const ANONYMIZED_APPUSERID_INDICATOR = 'anonymized';
const ANONYMIZED_APPUSERID_DISPLAY = '********';

export interface FormattedAppUserIDOptions {
  maxLength?: number;
}

export function formatAppUserID(
  appUserID: string | undefined,
  options?: FormattedAppUserIDOptions,
): string | undefined {
  if (!appUserID) return appUserID;

  if (appUserID === ANONYMIZED_APPUSERID_INDICATOR) {
    return ANONYMIZED_APPUSERID_DISPLAY;
  }

  if (options?.maxLength) {
    return appUserID?.slice(0, options.maxLength);
  }

  return appUserID;
}

export function withConditionalBrackets(
  value: string | undefined,
  bracketValue: string | undefined,
  options?: { fallbackToBracketValue?: boolean },
) {
  if (value && bracketValue) return `${value} (${bracketValue})`;
  if (value && !bracketValue) return value;
  if (options?.fallbackToBracketValue && bracketValue) return bracketValue;
  return '';
}

export function ensureString(value: unknown): string {
  if (value === undefined || value === null) return '';
  if (typeof value === 'string') return value;
  return String(value);
}
