import React from 'react';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import Translate from './i18n/Translate';
// @ts-ignore
import { formatPrice } from './ProductFormatPrice';
// @ts-ignore
import ColoredTableRow from './ColoredTableRow';
import ContentLink from './ContentLink';
import { AdditionalAttributesDialogButton } from '../additional-attributes';
import Strikethrough from './Strikethrough';
import LineItem from '../orders/types/LineItemTypes';

function formatPriceWithReferenceUnit(
  price?: number,
  currency?: string,
  referenceUnit?: string,
) {
  const formatted = formatPrice(price, currency);
  if (!formatted) {
    return null;
  }
  if (referenceUnit) {
    return `${formatted}/${referenceUnit}`;
  }
  return formatted;
}

interface ItemTableRowProps {
  item: LineItem;
  currency: string;
  productLink?: string;
  isColored?: boolean;
  alwaysCollapse?: boolean;
}

export default function ItemTableRow({
  item,
  currency,
  productLink,
  isColored = false,
  alwaysCollapse = false,
}: ItemTableRowProps) {
  if (!item || !currency) return null;

  const StyledTableCell = ({ children, ...props }: TableCellProps) => {
    if (item.priceModifiers || alwaysCollapse) {
      return (
        <TableCell size="small" sx={{ borderBottom: 'none' }} {...props}>
          {children}
        </TableCell>
      );
    }

    return (
      <TableCell {...props}>
        {children}
      </TableCell>
    );
  };

  const formattedListPrice = formatPriceWithReferenceUnit(
    (item.listPrice ?? item.price) as number,
    currency,
    item.referenceUnit,
  ) || '-/-';

  const hasDiscount = Boolean(item.listPrice) && item.listPrice !== item.price;

  return (
    <>
      <ColoredTableRow isColored={isColored}>
        <StyledTableCell>
          <ContentLink to={productLink}>{item.itemID}</ContentLink>
        </StyledTableCell>
        {productLink ? (
          <StyledTableCell><ContentLink to={productLink}>{item.name}</ContentLink></StyledTableCell>
        ) : (
          <StyledTableCell>{item.name}</StyledTableCell>
        )}
        <StyledTableCell><Translate id={`orders.itemType.${item.type}`} /></StyledTableCell>
        <StyledTableCell>{item.amount > 0 && item.amount}</StyledTableCell>
        {item.weight && item.weightUnit &&
          <StyledTableCell>{item.weight} {item.weightUnit}</StyledTableCell>
        }
        {item.units &&
          <StyledTableCell><Translate id="orders.units" data={{ units: item.units }} /></StyledTableCell>
        }
        {!item.weight && !item.weightUnit && !item.units &&
          <StyledTableCell>–</StyledTableCell>
        }
        <StyledTableCell>
          {hasDiscount && <Strikethrough>{formattedListPrice}</Strikethrough>}
          {!hasDiscount && formattedListPrice}
        </StyledTableCell>
        <StyledTableCell>
          {formatPriceWithReferenceUnit(item.price, currency, item.referenceUnit) || '-/-'}
        </StyledTableCell>
        <StyledTableCell>
          {
            !item.priceModifiers && !(item.priceModifiers || []).length &&
            (formatPrice(item.totalPrice, currency) || '-/-')
          }
        </StyledTableCell>
        <StyledTableCell align="right">
          {item.additionalAttributes && (
            <AdditionalAttributesDialogButton attributes={item.additionalAttributes} />
          )}
        </StyledTableCell>
      </ColoredTableRow>

      {(item.priceModifiers || []).map(modifier => (
        <ColoredTableRow key={`${item.id}-${modifier.name}-${modifier.price}`} isColored={isColored}>
          <StyledTableCell sx={{ textAlign: 'center', borderBottom: 'none' }}>
            <SubdirectoryArrowRightIcon fontSize="small" />
          </StyledTableCell >
          <StyledTableCell><Translate id={`orders.priceModifierMethod.${modifier.method}`} /></StyledTableCell>
          <StyledTableCell><Translate id={`orders.priceModifierAction.${modifier.action}`} /></StyledTableCell>
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell />
          <StyledTableCell>{formatPrice(modifier.price, currency)}</StyledTableCell>
          <StyledTableCell />
          <StyledTableCell align="right">
            {modifier.reasonCode && (
              <AdditionalAttributesDialogButton
                attributes={{ reasonCode: modifier.reasonCode }}
              />
            )}
          </StyledTableCell>
        </ColoredTableRow>
      ))}
      {item.priceModifiers && (
        <ColoredTableRow isColored={isColored}>
          <StyledTableCell />
          <StyledTableCell colSpan={6} />
          <StyledTableCell>
            {formatPrice(item.totalPrice, currency) || '-/-'}
          </StyledTableCell>
          <StyledTableCell />
        </ColoredTableRow>
      )}
    </>
  );
}
