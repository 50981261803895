import moment from 'moment';
// @ts-ignore
import { dateFormat } from '../components/LocaleDate';

export function splitDatetime(date: string, locale: string, part: 'date' | 'time') {
  const [datePart, ...timePart] = moment(date).format(dateFormat(locale, false)).split(' ');
  return part === 'date' ? datePart : timePart.join(' ');
}

export interface FormatTimestampOptions {
  invalidDateLabel?: string;
}

export function formatTimestamp(timestamp: string, options?: FormatTimestampOptions) {
  const unixTimestamp = Date.parse(timestamp);
  if (!(unixTimestamp >= 0)) return options?.invalidDateLabel || 'Invalid Date';
  const parsedDatetime = new Date(unixTimestamp);
  return parsedDatetime.toLocaleString();
}
