import React from 'react';
import Translate from './i18n/Translate';
import { formatPrice } from './ProductFormatPrice';
import LineItemTableRow from './LineItemTableRow';
// @ts-ignore
import LineItemTotalRow from './LineItemTotalRow';
// @ts-ignore
import PaperTable from './PaperTable';
import LineItem from '../orders/types/LineItemTypes';
import ReferringLineItemTableRow from './ReferringLineItemTableRow';

interface Price {
  price: number | undefined;
  subTotal: number | undefined;
  netPrice: number | undefined;
  surcharge: number,
}

interface OrderViewLineItemsProps {
  lineItems: LineItem[];
  currency: string;
  project: string;
  referringItems: Record<string, LineItem[]>;
  price: Price;
}

export default function OrderViewLineItems({
  lineItems,
  currency,
  project,
  referringItems,
  price,
}: OrderViewLineItemsProps) {
  const getReferringItems = (itemId: string | undefined) => {
    if (!itemId) return null;
    return referringItems[itemId] || null;
  };

  if (!lineItems || !currency) return null;

  return (
    <PaperTable
      headline={<Translate id="orders.items" />}
      tableHeader={[
        <Translate id="product.id" />,
        <Translate id="product.name" />,
        <Translate id="orders.itemType.tableHeader" />,
        <Translate id="orders.amount" />,
        <Translate id="orders.weight" />,
        <Translate id="orders.listPrice" />,
        <Translate id="orders.price" />,
        <Translate id="orders.totalPrice" />,
        '',
      ]}
    >
      {(lineItems || []).map((item: LineItem, index: number) => (
        <React.Fragment key={`${item.id}`} >
          <LineItemTableRow
            item={item}
            currency={currency}
            productLink={`/${project}/products/${item.itemID}`}
            isColored={(index % 2) === 0}
            alwaysCollapse={!!getReferringItems(item.id)}
          />
          {(getReferringItems(item.id) || []).map((referringItem: LineItem) => (
            <ReferringLineItemTableRow
              key={`${referringItem.id}`}
              item={referringItem}
              currency={currency}
              isColored={(index % 2) === 0}
            />
          ))}

        </React.Fragment>
      ))}

      {!!price?.subTotal &&
        <LineItemTotalRow
          label={<Translate id="orders.subTotal" />}
          value={formatPrice(price.subTotal, currency)}
          sx={{ border: 'none' }}
        />
      }
      <LineItemTotalRow label={<Translate id="orders.totalNet" />} value={price?.netPrice ? formatPrice(price.netPrice, currency) : '-/-'} sx={{ border: 'none' }} />
      {!!price?.surcharge &&
        <LineItemTotalRow
          label={<Translate id="orders.totalSurcharge" />}
          value={formatPrice(price.surcharge, currency)}
          sx={{ border: 'none' }}
        />
      }
      <LineItemTotalRow label={<Translate id="orders.total" />} value={price?.price ? formatPrice(price.price + price.surcharge, currency) : '-/-'} />
    </PaperTable>
  );
}
