import handlePromiseResponseStatus from '../components/RequestUtils';
import { prependApiHost } from '../urls';
import actions from './constants';

const showErrorNotification = (message: string) => ({
  type: actions.SHOW_NOTIFICATION,
  payload: {
    message,
    error: true,
  },
});

const authorizationError = () => ({ type: actions.AUTHORIZATION_ERROR });

const fetchWithTimeout =
  (
    url: RequestInfo | URL,
    options: any,
    controller?: AbortController,
    timeout = 30000,
  ) =>
    new Promise((
      resolve: (resp: Response) => void,
      reject: (resp: Response) => void,
    ) => {
      const abortController = controller ?? new AbortController();
      const { signal } = abortController;
      const timer = setTimeout(
        () => {
          abortController.abort();
        },
        timeout,
      );

      fetch(url, Object.assign({}, options, { signal }))
        .then(resolve, reject)
        .finally(() => clearTimeout(timer));
    });

async function getResponseError(response: Response) {
  try {
    const data = await response.json();
    return data.error ?? null;
  } catch (e) {
    return null;
  }
}

const requestHandler =
  (
    dispatch: (value: any) => void,
    resolve: (value: any) => void,
    reject: (value: any) => void,
  ) => async (response: Response) => {
    if (response.status >= 200 && response.status < 300) {
      const contentType = response.headers.get('content-type') || '';
      if (contentType.indexOf('json') !== -1) {
        resolve(response.json());
        return;
      }
      if (contentType.indexOf('text') !== -1) {
        resolve(response.text());
        return;
      }
      if (contentType.indexOf('tar') !== -1 || contentType.indexOf('zip') !== -1) {
        resolve(response.blob());
        return;
      }
      resolve({});
      return;
    }

    const error = await getResponseError(response);
    if (response.status === 403 && error?.type === 'too_many_records') {
      reject(error);
      return;
    }

    if (response.status === 401 || response.status === 403) {
      dispatch(authorizationError());
      reject(error);
      return;
    }

    if (response.status === 400 || response.status === 404) {
      resolve(undefined);
      return;
    }

    dispatch(showErrorNotification(`Error processing request. (Code ${response.status})`));
    reject(response);
  };

type ErrorNotification = {
  type: string; payload: { message: string; error: boolean; };
};

const errorHandler =
  (
    dispatch: (notification: ErrorNotification) => void,
    reject: (reason: any) => void,
  ) => (error: any) => {
    dispatch(showErrorNotification(`Error communicating with the server. (Code ${error})`));
    reject(error);
  };

const resolveURL = (getState: () => any, url: string) => {
  let resolvedURL = url;
  if (url.indexOf('{project}') !== -1) {
    const projectId = getState().selectedProjectId;
    resolvedURL = url.replace(/{project}/g, projectId);
  }
  return prependApiHost(resolvedURL);
};

const streamToJson = (response: Response) =>
  response.text().then((
    text =>
      text.toString()
        .split('\n')
        .filter(item => item.length > 0)
        .map(value => JSON.parse(value))
  ));

const get =
  (
    dispatch: (value: any) => void,
    getState: () => any,
    url: string,
    contentType: string,
    controller?: AbortController,
    timeout?: number,
  ) =>
    new Promise((resolve, reject) => {
      fetchWithTimeout(
        resolveURL(getState, url),
        {
          headers: {
            Authorization: `Bearer ${getState().token.raw}`,
            Accept: contentType,
            'Cache-Control': 'no-store, no-cache',
          },
          method: 'GET',
        },
        controller,
        timeout,
      )
        .then(
          requestHandler(dispatch, resolve, reject),
          errorHandler(dispatch, reject),
        );
    });

const stream =
  (
    dispatch: (value: any) => void,
    getState: () => any,
    url: string,
    contentType: string,
    controller: AbortController | undefined,
    timeout: number,
  ) =>
    new Promise((resolve, reject) => {
      fetchWithTimeout(
        resolveURL(getState, url),
        {
          headers: {
            Authorization: `Bearer ${getState().token.raw}`,
            Accept: contentType,
            'Cache-Control': 'no-store, no-cache',
          },
          method: 'GET',
        },
        controller,
        timeout,
      )
        .then(handlePromiseResponseStatus)
        .then(streamToJson)
        .then(
          response => resolve(response),
          async (err) => {
            if (err.status === 404) {
              resolve(null);
            } else if (err.status === 401 || err.status === 403) {
              dispatch(authorizationError());
              reject(err);
            } else {
              dispatch(showErrorNotification(`Error processing request. (Code ${err.status})`));
              reject(err);
            }
          },
        );
    });

const requestApi = (
  getState: () => any,
  url: string,
  method: string,
  body: any,
  controller?: AbortController,
): Promise<Response> =>
  new Promise((resolve) => {
    const abortController = controller ?? new AbortController();
    const { signal } = abortController;
    fetch(
      resolveURL(getState, url),
      {
        signal,
        headers: {
          Authorization: `Bearer ${getState().token.raw}`,
          'content-type': 'application/json',
        },
        body,
        method,
      },
    ).then((response) => {
      resolve(response);
    });
  });


export {
  get,
  stream,
  requestApi,
  resolveURL,
  fetchWithTimeout,
  authorizationError,
  showErrorNotification,
};
