import React from 'react';
import { WrappedFunctionProps } from '../types';
import { SelectOptionsField } from '../../form/input';

export default function SelectField({
  key,
  translation,
  language,
  control,
  data,
}: WrappedFunctionProps) {
  const options = data?.enum?.map((option: string) => ({
    option,
    translation: translation?.enumNames?.[option] || option,
  })) || [];
  return (
    <SelectOptionsField
      control={control}
      name={key}
      label={translation?.title?.[language] || key}
      helperText={translation?.description?.[language]}
      options={options}
      valueFrom="option"
      labelFrom={`translation.${language}`}
      multiple
    />
  );
}
