import React, { ReactNode, forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CustomFormProps,
  DefaultFormActions,
  Form,
  useEnhancedForm,
} from '../form';
import { flattenUISchema } from './helper';
import {
  UISchema,
  UISchemaLanguage,
  UISchemaScope,
  FunctionMapping,
} from './types';
import toElements from './dynaFormFactory';
import mappings from './mapping';
import { CustomFormRef } from '../form/CustomFormProps';
import { useForceSubmitDialog } from '../shop/useForceSubmitDialog';

const DEFAULT_VALUES = {};

export const mapping: FunctionMapping = mappings;

export interface DynaFormProps extends CustomFormProps {
  uiSchema: UISchema;
  currentScope: UISchemaScope;
}

const DynaForm = forwardRef<CustomFormRef, DynaFormProps>(({
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
  uiSchema,
  currentScope = UISchemaScope.PROJECT,
}, ref) => {
  const { i18n } = useTranslation();
  const language = useMemo<UISchemaLanguage>(() => (
    (i18n.resolvedLanguage || 'de') as UISchemaLanguage
  ), [i18n.resolvedLanguage]);

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const [dynaFormRoot, setDynaFormRoot] = useState<ReactNode[]>([]);

  const { ConfirmationDialog } = useForceSubmitDialog({ formState, ref });

  useEffect(() => {
    if (!uiSchema) return;
    setDynaFormRoot(toElements(
      {
        elementDescriptions: flattenUISchema((uiSchema as UISchema).properties),
        mapping,
      },
      {
        control,
        language,
        currentScope,
      },
    ));
  }, [control, currentScope, language, uiSchema]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {dynaFormRoot}

      <ConfirmationDialog />
      <DefaultFormActions
        formState={formState}
      />
    </Form>
  );
});

export default DynaForm;
