import { FunctionMapping } from '../types';
import CheckboxField from './CheckboxField';
import ColorField from './ColorField';
import ColorAlphaField from './ColorAlphaField';
import Fallback from './Fallback';
import Fieldset from './Fieldset';
import NumberField from './NumberField';
import IntegerField from './IntegerField';
import SelectField from './SelectField';
import SelectPaymentTerminalIconField from './SelectPaymentTerminalIconField';
import TextField from './TextField';
import ImageField from './ImageField';
import MultiSelectField from './MultiSelectField';

const mapping: FunctionMapping = {
  Fieldset,
  TextField,
  NumberField,
  IntegerField,
  CheckboxField,
  ColorField,
  ColorAlphaField,
  SelectPaymentTerminalIconField,
  SelectField,
  MultiSelectField,
  ImageField,
  Fallback,
} as const;

export default mapping;
