import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {
  CustomFormProps,
  DefaultFormActions,
  Fieldset,
  Form,
  useEnhancedForm,
} from '../form';
import { TextField } from '../form/input';
import CheckboxField from '../form/input/CheckboxField';
// @ts-ignore
import PaperTable from '../components/PaperTable';
// @ts-ignore
import PaperTableRow from '../components/PaperTableRow';
import { ColumnsProp, formatCell, SortableTable } from '../SortableTable';
import PanelHeader from '../panel/PanelHeader';

export interface AppUserFormProps extends CustomFormProps {
  onErase: () => void;
}

const DEFAULT_VALUES = {};

export default function EditAppUserForm({
  onErase,
  defaultValues = DEFAULT_VALUES,
  errors = {},
  onSubmit,
}: AppUserFormProps) {
  const { t } = useTranslation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const data = defaultValues;

  const {
    handleSubmit,
    control,
    formState,
  } = useEnhancedForm({ defaultValues, errors });

  const headCells = useMemo<ColumnsProp>(() => [
    {
      key: 'createdAt',
      disablePadding: false,
      displayName: t('appUser.agreementList.createdAt'),
      type: 'datetime',
    },
    {
      key: 'document',
      disablePadding: false,
      displayName: t('appUser.agreementList.document'),
    },
    {
      key: 'version',
      disablePadding: false,
      displayName: t('appUser.agreementList.version'),
    },
  ], [t]);

  return (
    <>
      <Dialog open={deleteDialogOpen} maxWidth="xs">
        <DialogTitle>
          {t('appUser.eraseUser.confirmationTitle')}
        </DialogTitle>

        <DialogContent dividers>
          <Typography>
            {t('appUser.eraseUser.confirmationBody')}
          </Typography>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => { setDeleteDialogOpen(false); }}>
            {t('confirmation.buttons.decline')}
          </Button>
          <Button onClick={onErase} color="primary" variant="contained">
            {t('confirmation.buttons.confirm')}
          </Button>
        </DialogActions>
      </Dialog>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Fieldset category="user" legend={t('appUser.headlines.userDetails')}>
          <TextField
            control={control}
            name="details.firstName"
            label={t('appUser.firstName')}
          />
          <TextField
            control={control}
            name="details.lastName"
            label={t('appUser.lastName')}
          />
          <TextField
            control={control}
            name="details.dateOfBirth"
            label={t('appUser.dateOfBirth')}
          />
          <TextField
            control={control}
            name="details.email"
            label={t('appUser.email')}
          />
          <TextField
            control={control}
            name="details.street"
            label={t('appUser.street')}
          />
          <TextField
            control={control}
            name="details.zip"
            label={t('appUser.zip')}
          />
          <TextField
            control={control}
            name="details.city"
            label={t('appUser.city')}
          />
          <TextField
            control={control}
            name="details.state"
            label={t('appUser.state')}
          />
          <TextField
            control={control}
            name="details.country"
            label={t('appUser.country')}
          />
          <TextField
            control={control}
            name="details.acceptedPrivacyTermsAt"
            label={t('appUser.acceptedPrivacyTermsAt')}
          />
          <TextField
            control={control}
            name="details.acceptedTermsOfUseAt"
            label={t('appUser.acceptedTermsOfUseAt')}
          />
          <CheckboxField
            control={control}
            name="details.emailIsVerified"
            label={t('appUser.emailIsVerified')}
          />
        </Fieldset>

        <PaperTable headline={t('appUser.headlines.user')}>
          <PaperTableRow
            label={t('appUser.id')}
            value={data?.id}
          />
          <PaperTableRow
            label={t('appUser.phoneNumber')}
            value={data?.phoneNumber || '-/-'}
          />
          <PaperTableRow
            label={t('appUser.userCreatedAt')}
            value={formatCell(data?.createdAt, 'datetime')}
          />
        </PaperTable>

        <Paper elevation={4}>
          <PanelHeader>{t('appUser.headlines.agreements')}</PanelHeader>
          {data.agreements?.length ?
            <SortableTable
              data={data.agreements || []}
              columnsMetadata={headCells}
              title=""
              filterPlaceholder={t('appUser.agreementList.filterPlaceholder')}
              labelRowsPerPage={t('appUser.agreementList.labelRowsPerPage')}
              verticalToolbarPadding={4}
            />
            :
            <div style={{ fontWeight: 400, fontSize: '0.875rem', margin: '16px' }}>
              {t('appUser.agreementList.empty')}
            </div>
          }
        </Paper>

        <DefaultFormActions
          formState={formState}
          additionalButtons={(
            <Button
              aria-label="delete"
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={() => { setDeleteDialogOpen(true); }}
            >
              {t('appUser.eraseUser.button')}
            </Button>
          )}
        />
      </Form>
    </>
  );
}
