import Currency from 'currency.js';

interface CurrencySettings {
  fractionDigits: number;
  priceFormat: number;
  priceUnit: string;
  currency: string;
  locale: string,
  digitFormatting: { separator?: string, decimal?: string };
  customFormatter?: null | ((value: number) => string);
}

function getCurrencySettings(computedCurrency: string, referenceUnit?: string) {
  const settings: CurrencySettings = {
    fractionDigits: 0,
    priceFormat: 1,
    priceUnit: referenceUnit ? `/${referenceUnit}` : '',
    currency: computedCurrency,
    locale: 'en-GB',
    digitFormatting: { separator: '.', decimal: ',' },
    customFormatter: null,
  };

  switch (computedCurrency) {
    case 'HUF':
      settings.fractionDigits = 0;
      settings.priceFormat = 1;
      settings.locale = 'hu-HU';
      break;
    case 'EUR':
      settings.fractionDigits = 2;
      settings.priceFormat = 100;
      settings.locale = 'de-DE';
      break;
    case 'RON':
      settings.fractionDigits = 2;
      settings.priceFormat = 100;
      settings.locale = 'ro-RO';
      break;
    case 'CHF':
      settings.digitFormatting = {}; // use default
      settings.fractionDigits = 2;
      settings.priceFormat = 100;
      settings.locale = 'de-CH';
      break;
    case 'AUD':
      settings.customFormatter = (value: number) => new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value / 100).toString().replace('$', 'AU$') + settings.priceUnit;
      break;
    case 'DKK':
      settings.customFormatter = (value: number) => new Intl.NumberFormat('da-DK', {
        style: 'currency',
        currency: 'DKK',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value / 100).toString().replace('kr.', `kr${settings.priceUnit}`);
      break;
    default:
  }

  return settings;
}

function formatNumbersByCurrency(currencySettings: CurrencySettings, value: number) {
  const {
    currency, fractionDigits, priceFormat, priceUnit, locale, customFormatter,
  } = currencySettings;

  if (customFormatter) {
    return customFormatter(value);
  }

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: 2,
  }).format(value / priceFormat).toString() + priceUnit;
}

function formatPrice(value?: number, currency?: string, referenceUnit?: string) {
  const computedCurrency = currency || 'EUR';
  const currencySettings = getCurrencySettings(computedCurrency, referenceUnit);

  if (!value && value !== 0) {
    return null;
  }
  return formatNumbersByCurrency(currencySettings, value);
}

function formatDigits(value: number, currency: string, referenceUnit: string) {
  const computedCurrency = currency || 'EUR';
  const currencySettings = getCurrencySettings(computedCurrency, referenceUnit);
  const x = formatNumbersByCurrency(currencySettings, value);
  return Currency(x, currencySettings.digitFormatting).value;
}

export { formatDigits, formatPrice };
